<template>
    <div>
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <h2 class="content-header-title float-start mb-0">
              Picnic Package Amenity Master
            </h2>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-2">
          <div class="col-12">
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb float-end">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Picnic Package Amenity Master</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-xl-12 col-md-12 col-12">
              <div class="card card-congratulation-medal">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="d-flex col-12">
                      <div class="float-start col-6">
                        <div class="input-group input-group-merge w-50">
                          <span class="input-group-text" id="basic-addon-search2"
                            ><vue-feather type="search"></vue-feather></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-label="Search..."
                            aria-describedby="basic-addon-search2"
                            v-model="search"
                            @keyup="searchData"
                          />
                        </div>
                      </div>
                      <!-- data-bs-toggle="modal"
                      data-bs-target="#modals-slide-in" -->
                      <div class="float-end col-6">
                        <button
                          type="button"
                          class="btn btn-gradient-primary float-end"
                          @click="add"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <section id="ajax-datatable">
                    <div class="row">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-datatable">
                            <table
                              class="dt-column-search table table-responsive"
                            >
                              <thead>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                  <td>{{ index + paginationData.from }}</td>
                                  <td>{{ li.picnic_package.pack_name }}</td>
                                  <td>{{ li.amenity_master.amenity_name }}</td>
                                  <td>{{ tConvert(li.from_time) + ' To ' + tConvert(li.to_time) }}</td>
                                  <td>{{ li.created_at }}</td>
                                  <td>{{ li.updated_at }}</td>
                                  <td class="d-flex">
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-primary rounded"
                                          @click="edit(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="edit" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-danger rounded"
                                          @click="deleteDes(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="trash-2" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-show="list.length === 0">
                                  <td colspan="7" class="text-center">No Data Found</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </tfoot>
                            </table>
                            <div class="row d-flex">
                              <div class="col-12 d-flex">
                                <div class="col-6 showing_entry">
                                  Showing {{ paginationData.from }} to
                                  {{ paginationData.to }} of
                                  {{ paginationData.total }} entries
                                </div>
                                <div class="col-6">
                                  <Pagination
                                    :data="paginationData"
                                    @pagination-change-page="getResults"
                                    show-disabled="true"
                                    limit="3"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <teleport to="#modal">
      <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
        <div class="modal-dialog">
          <form
            class="add-new-user modal-content pt-0"
            method="post"
            @submit.prevent="submitForm"
          >
            <input type="hidden" name="id" :v-modal="id" />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="[isShow='', isDisplay='none']"
            >
              ×
            </button>
            <div class="modal-header mb-1">
              <h4 class="modal-title" id="exampleModalLabel">
                {{ modalName }} Picnic Package Amenity Master
              </h4>
            </div>
            <div class="modal-body flex-grow-1">
               <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Package Name</label>
                <Select2 v-model="form.package_id" :options="$store.state.picnicPackageData" :settings="{ placeholder: 'Choose Picnic Package' }" @select="packEvent($event)"/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: packNameError}" > Please enter Package. </div>
              </div>
               <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Amenity Name</label>
                <Select2 v-model="form.amenity_id" :options="$store.state.amenityData" :settings="{ placeholder: 'Choose Amenity' }" @select="amenityEvent($event)"/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: amenityNameError}" > Please enter Amenity. </div>
              </div>
              <div class="mb-1">
                <div class="row">
                    <div class="col-5">
                        <label class="form-label" for="basic-addon-name">From Time</label>
                        <input
                            type="time" class="form-control" name="from_time" v-model="form.from_time" step="2" id="from_time" placeholder="FromTime" @keyup="
                            form.from_time.toString().length > 0
                                ? [(fromTimeError = 'none'), (disabled = false)]
                                : (fromTimeError = 'block')
                            "/>
                        <div class="invalid-feedback fw-bold fst-italic" :style="{display: fromTimeError}" > Please enter FromTime. </div>
                    </div>
                    <div class="col-1 text-center">
                        <label class="form-label" for="basic-addon-name">&nbsp;</label><br>
                        <label class="form-label" style="font-size: 25px;" for="basic-addon-name"> To </label>
                    </div>
                    <div class="col-5">
                        <label class="form-label" for="basic-addon-name">To Time</label>
                        <input
                            type="time" class="form-control" name="to_time" v-model="form.to_time" step="2" id="to_time" placeholder="ToTime" @keyup="
                            form.to_time.toString().length > 0
                                ? [(toTimeError = 'none'), (disabled = false)]
                                : (toTimeError = 'block')
                            "/>
                        <div class="invalid-feedback fw-bold fst-italic" :style="{display: toTimeError}" > Please enter ToTime. </div>
                    </div>
                </div>
             </div>
              <button
                type="submit"
                class="btn btn-primary me-1 data-submit"
                :disabled="disabled"
              >
                Submit
              </button>
              <button
                type="reset"
                class="btn btn-outline-secondary"
                :disabled="disabled"
                data-bs-dismiss="modal"
                @click="[isShow='', isDisplay='none']"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </teleport>
  </template>
  
  <script>
  import axios from "axios";
  import LaravelVuePagination from "laravel-vue-pagination";
  import Form from 'vform';
  import Select2 from 'vue3-select2-component';
  
  export default {
    name: "PicnicPackageInclude",
    components: {
      Pagination: LaravelVuePagination,
      Select2
    },
    data() {
      return {
        form: new Form({
            id: null,
            package_id: "",
            amenity_id: "",
            from_time: "",
            to_time: "",
        }),
        response: "",
        disabled: false,
        header: [],
        list: [],
        modalName: "Add",
        paginationData: {},
        search: null,
        packNameError: "none",
        amenityNameError: "none",
        fromTimeError: "none",
        toTimeError: "none",
        isShow: "",
        isDisplay: "none",
        token: localStorage.getItem('auth_token')
      };
    },
    async mounted() {
      this.header = [
        "Sr. no.",
        "Package Name",
        "Amenity Name",
        "FromTime To ToTime",
        "Created Date",
        "Updated Date",
        "Action",
      ];
      this.getResults();
      this.$store.dispatch('getPicnicPackageList');
      this.$store.dispatch('getAmenityList');
    },
    methods: {
        packEvent({ id }) {
            this.form.package_id = id;
            this.disabled = false;
            if(this.form.package_id.length > 0) {
                this.packNameError = "none";
            }
        },
        amenityEvent({ id }) {
            this.form.amenity_id = id;
            this.disabled = false;
            if(this.form.amenity_id.length > 0) {
                this.amenityNameError = "none";
            }
        },
      async add() {
        (this.form.id = null),
        (this.form.package_id = ""),
        (this.form.amenity_id = ""),
        (this.form.from_time = ""),
        (this.form.to_time = ""),
        (this.response = "");
        this.form.tags = [];
        this.isShow = "show"
        this.isDisplay = "block"
      },
      async getResults(page = 1) {
        await axios.get(process.env.VUE_APP_API_URL+"/picnic_package_amenity_master?page=" + page, 
          {
            headers: {
              "Content-Type": "application/json",
              'Accept': "application/json",
              "Authorization": `Bearer ${this.token}`
            }
          })
          .then((response) => {
            this.paginationData = response.data.data;
          });
        this.list = this.paginationData.data;
      },
      async submitForm() {
        this.disabled = true;
        if (this.form.package_id.length === 0) {
          this.packNameError = "block";
        } else if (this.form.amenity_id.length === 0) {
          this.amenityNameError = "block";
        } else if (this.form.from_time.length === 0) {
          this.fromTimeError = "block";
        } else if (this.form.to_time.length === 0) {
          this.toTimeError = "block";
        } else {
            this.packNameError = "none";
            this.amenityNameError = "none";

          if (this.form.id) {
            await this.form.put(process.env.VUE_APP_API_URL+"/picnic_package_amenity_master/" + this.form.id,
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            })
            .then((response) => {
              if (response.data.status) {
                this.$toast.success(response.data.message);
                this.isShow = ""
                this.isDisplay = "none"
                this.disabled = false;
                this.getResults();
              } else {
                this.$toast.error(response.data.error?.[0]);
                this.disabled = false;
              }
            })
            .catch((error) => {
              this.$toast.error(error);
              this.disabled = false;
            });
          } else {
            await this.form.post(process.env.VUE_APP_API_URL+"/picnic_package_amenity_master",
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              })
              .then((response) => {
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                  this.isShow = ""
                  this.isDisplay = "none"
                  this.disabled = false;
                  this.getResults();
                } else {
                  this.$toast.error(response.data.error?.[0]);
                  this.disabled = false;
                }
              })
              .catch((error) => {
                this.$toast.error(error);
                this.disabled = false;
              });
          }
        }
      },
      deleteDes(id) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios
              .delete(process.env.VUE_APP_API_URL+"/picnic_package_amenity_master/" + id,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                }
              )
              .then(response => {
                this.getResults();
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
              })
              .catch((error) => {
                this.$toast.error(error);
              });
          }
        });
      },
      async edit(id) {
        this.packNameError = "none";
        this.amenityNameError = "none";
        this.fromTimeError = "none";
        this.toTimeError = "none";

        this.disabled = false

        const output = await axios.get(process.env.VUE_APP_API_URL+"/picnic_package_amenity_master" +(id ? "/" + id : ""),
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                });
        this.form.id = output.data.data?.id;
        this.form.package_id = output.data.data?.package_id;
        this.form.amenity_id = output.data.data?.amenity_id;
        this.form.from_time = output.data.data?.from_time;
        this.form.to_time = output.data.data?.to_time;
        
        this.modalName = "Edit";
        this.isShow = "show"
        this.isDisplay = "block"
      },
      async searchData() {
        await axios
          .post(process.env.VUE_APP_API_URL+"/picnic_package_amenity_master/search",
            {
              search: this.search,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.list = response.data.data.data;
              this.isShow = ""
              this.isDisplay = "none"
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
      tConvert (time) {
            // Check correct time format and split into components
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice (1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join (''); // return adjusted time or original string
        }   
    },
  };
  </script>
  <style scoped>
  /* a {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-weight: bold;
    text-decoration: none;
    color: #00003a;
    font-size: 20px;
  } */
    
    
  /*tag input style*/
    
    .tag-input {
      width: 100%;
      border: 1px solid #D9DFE7;
      background: #fff;
      border-radius: 4px;
      font-size: 0.9em;
      min-height: 45px;
      box-sizing: border-box;
      padding: 0 10px;
      margin-bottom: 10px;
    }
  
    .tag-input__tag {
      height: 24px;
      color: white;
      float: left;
      font-size: 14px;
      margin-right: 10px;
      background-color: #7367f0;
      border-radius: 15px;
      margin-top: 10px;
      line-height: 24px;
      padding: 0 8px;
      transition: 0.1s ease background;
    }
  
    .tag-input__tag > span {
      cursor: pointer;
      opacity: 0.75;
      display: inline-block;
      margin-left: 8px;
    }
  
    .tag-input__text {
      border: none;
      outline: none;
      font-size: 1em;
      line-height: 40px;
      background: none;
    }
  </style>